var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kontakt"},[_c('page-title',{attrs:{"slug":"kontakt","alt":"Kontakt"}},[_vm._v("Kontakt")]),_c('v-page',[_c('div',{staticClass:"cols"},[_c('div',{staticClass:"info"},[_c('h2',{staticClass:"article-title"},[_vm._v("KONTAKTDATEN")]),_c('article',{staticClass:"article"},[_c('p',{staticClass:"email text"},[_c('span',[_vm._v("Email:")]),_c('span',[_vm._v("grimmer.karl@gmail.com")])]),_c('p',{staticClass:"telefon text"},[_c('span',[_vm._v("Telefon:")]),_c('span',[_vm._v("0699 1943 49 59")])])]),_c('h2',{staticClass:"article-title"},[_vm._v("PRAXISADRESSE")]),_c('article',{staticClass:"article"},[_c('p',{staticClass:"text"},[_vm._v("Mariahilfer Straße 117/2/21")]),_c('p',{staticClass:"text"},[_vm._v("1060 Wien")])]),_c('h2',{staticClass:"article-title"},[_vm._v("ERREICHBARKEIT")]),_c('article',{staticClass:"article"},[_c('p',{staticClass:"text"},[_vm._v("U3 Zieglergasse (Aufgang Webgasse)")]),_c('p',{staticClass:"text"},[_vm._v("U6 Westbahnhof")])]),_c('h2',{staticClass:"article-title"},[_vm._v("ZWEITPRAXIS")]),_c('article',{staticClass:"article"},[_c('p',{staticClass:"text"},[_vm._v("Wiedner Hauptstraße 33/11")]),_c('p',{staticClass:"text"},[_vm._v("1040 Wien")])])]),_c('div',{staticClass:"map-wrap"},[_c('div',{staticClass:"map"},[_c('responsive-image',{attrs:{"urls":{
              webp: [
                { size: 640, url: require('@/assets/img/lageplan/lageplan_640.webp') },
                { size: 800, url: require('@/assets/img/lageplan/lageplan_800.webp') },
                { size: 960, url: require('@/assets/img/lageplan/lageplan_960.webp') },
                { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.webp') } ],
              png: [
                { size: 640, url: require('@/assets/img/lageplan/lageplan_640.png') },
                { size: 800, url: require('@/assets/img/lageplan/lageplan_800.png') },
                { size: 960, url: require('@/assets/img/lageplan/lageplan_960.png') },
                { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.png') } ],
            },"sizes":"100vw","alt":"Supervision und Coaching"}})],1),_c('a',{ref:"nofollow",staticClass:"google",attrs:{"href":"//www.google.com/maps/place/Mariahilfer+Str.+117,+1060+Wien","target":"_blank"}},[_c('span',{staticClass:"google-icon"},[_c('img',{attrs:{"src":require('@/assets/img/google_maps.svg'),"alt":"Google Maps Symbol"}})]),_c('span',{staticClass:"google-text"},[_vm._v("In Google Maps ansehen")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }