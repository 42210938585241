<template>
  <div class="kontakt">
    <page-title slug="kontakt" alt="Kontakt">Kontakt</page-title>
    <v-page>
      <div class="cols">
        <div class="info">
          <h2 class="article-title">KONTAKTDATEN</h2>
          <article class="article">
            <p class="email text">
              <span>Email:</span>
              <span>grimmer.karl@gmail.com</span>
            </p>
            <p class="telefon text">
              <span>Telefon:</span>
              <span>0699 1943 49 59</span>
            </p>
          </article>

          <h2 class="article-title">PRAXISADRESSE</h2>
          <article class="article">
            <p class="text">Mariahilfer Straße 117/2/21</p>
            <p class="text">1060 Wien</p>
          </article>

          <h2 class="article-title">ERREICHBARKEIT</h2>
          <article class="article">
            <p class="text">U3 Zieglergasse (Aufgang Webgasse)</p>
            <p class="text">U6 Westbahnhof</p>
          </article>

          <h2 class="article-title">ZWEITPRAXIS</h2>
          <article class="article">
            <p class="text">Wiedner Hauptstraße 33/11</p>
            <p class="text">1040 Wien</p>
          </article>
        </div>
        <div class="map-wrap">
          <div class="map">
            <responsive-image
              :urls="{
                webp: [
                  { size: 640, url: require('@/assets/img/lageplan/lageplan_640.webp') },
                  { size: 800, url: require('@/assets/img/lageplan/lageplan_800.webp') },
                  { size: 960, url: require('@/assets/img/lageplan/lageplan_960.webp') },
                  { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.webp') },
                ],
                png: [
                  { size: 640, url: require('@/assets/img/lageplan/lageplan_640.png') },
                  { size: 800, url: require('@/assets/img/lageplan/lageplan_800.png') },
                  { size: 960, url: require('@/assets/img/lageplan/lageplan_960.png') },
                  { size: 1280, url: require('@/assets/img/lageplan/lageplan_1280.png') },
                ],
              }"
              sizes="100vw"
              alt="Supervision und Coaching"
            ></responsive-image>
          </div>
          <a
            class="google"
            href="//www.google.com/maps/place/Mariahilfer+Str.+117,+1060+Wien"
            target="_blank"
            ref="nofollow"
          >
            <span class="google-icon">
              <img :src="require('@/assets/img/google_maps.svg')" alt="Google Maps Symbol" />
            </span>
            <span class="google-text">In Google Maps ansehen</span>
          </a>
        </div>
      </div>
    </v-page>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle'
import VPage from '@/components/Page'
import ResponsiveImage from '@/components/ResponsiveImage'

export default {
  name: 'Kontakt',
  components: {
    PageTitle,
    VPage,
    ResponsiveImage,
  },
}
</script>

<style scoped>
@media all and (min-width: 960px) {
  .cols {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }
}

.map-wrap {
  position: relative;
  width: 100vw;
  left: 50%;
  margin: 64px auto 0;
  background-color: var(--col-white);
  transform: translateX(-50%);
}

.map-wrap::before {
  content: '';
  display: block;
  position: relative;
  width: 100%;
  padding-bottom: calc(100% / 3 * 2);
}

@media all and (min-width: 560px) {
  .map-wrap {
    width: min(100%, 640px);
    left: initial;
    transform: none;
  }
}

@media all and (min-width: 960px) {
  .map-wrap {
    margin: auto;
    order: -1;
  }
}

.map {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.google {
  --google-padding: 8px;
  --icon-size: 40px;
  display: flex;
  align-items: center;
  position: absolute;
  height: calc(var(--icon-size) + 2 * var(--google-padding));
  bottom: 16px;
  right: 16px;
  padding: var(--google-padding);
  border-radius: 4px;
  background: var(--col-white);
  box-shadow: 2px 3px 15px 0 rgba(0, 0, 0, 0.075);
  overflow: hidden;
}

.google-icon {
  display: block;
  height: var(--icon-size);
  width: var(--icon-size);
  margin-right: 8px;
}

.google-icon img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.google-text {
  width: 0;
  margin-right: -8px;
  font-size: var(--font-size-regular);
  font-weight: var(--weight-sans-regular);
  line-height: 1.3em;
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  transition: width 375ms cubic-bezier(0, 0.5, 0.5, 1), opacity 375ms linear;
}

.has-hover .map-wrap:hover .google-text {
  width: calc(12.5 * var(--font-size-regular));
  opacity: 1;
}

@media all and (min-width: 560px) {
  .info {
    width: min(100%, 640px);
    margin-left: auto;
    margin-right: auto;
  }
}

@media all and (min-width: 960px) {
  .info {
    margin-left: min(8vw, 128px);
    margin-right: initial;
  }
}

.article-title:first-of-type {
  margin-top: 0;
}

.email,
.telefon {
  display: grid;
  grid-template-columns: 5em auto;
}
</style>
